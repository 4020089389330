import React from 'react';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
  const navigate = useNavigate()

  const handleButtonClick = (redireccion) => {
    navigate(`/${redireccion}`);
  };
  return (
    <section className="relative bg-black bg-opacity-85 h-[563px] flex">
      <img
        src={`${process.env.PUBLIC_URL}/img/FotoEmpleoLanding.jpg`}
        alt="Hero Background"
        className="absolute inset-0 w-full h-full object-cover opacity-60"
      />
      <div className="relative z-10 flex flex-wrap w-full px-4 lg:px-16">
        <div className="w-full lg:w-1/2 mb-8 lg:mb-0 lg:mt-24">
          <h1 className="text-5xl lg:text-4xl font-bold text-white text-center lg:text-left font-maison">
            ¿Queres trabajar<br></br> con nosotros?
          </h1>
        </div>
        <div className="w-full lg:w-1/2 flex justify-center items-center lg:justify-start">
          <div className='bg-[#228B60] w-[601px] h-[204px] rounded-lg flex flex-col items-center justify-center'>
            <h3 className='text-white font-satoshi font-bold text-xl mb-8'>Súmate a nuestro equipo<br></br> y envíanos tus datos.</h3>
            <button onClick={() => handleButtonClick('empleo')} className='bg-[#E0E0E0] w-3/4 py-2 rounded-md font-bold font-satoshi'>Empleos</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
