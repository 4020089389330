import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ClientesCarousel = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSLide: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1255,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return <Slider {...settings} className="z-50 w-full mb-32">
    <img className='max-w-40 max-h-40 mx-2' src={`${process.env.PUBLIC_URL}/img/Clientes/ClienteDinoMall.png`} alt='Dinosaurio mall' />
    <img className='max-w-40 max-h-40 mx-2' src={`${process.env.PUBLIC_URL}/img/Clientes/ClienteEasy.png`} alt='Dinosaurio mall' />
    <img className='max-w-40 max-h-40 mx-2' src={`${process.env.PUBLIC_URL}/img/Clientes/ClienteMultiVoice.png`} alt='Dinosaurio mall' />
    <img className='max-w-40 max-h-40 mx-2' src={`${process.env.PUBLIC_URL}/img/Clientes/ClientePortalRosario.png`} alt='Dinosaurio mall' />
    <img className='max-w-40 max-h-40 mx-2' src={`${process.env.PUBLIC_URL}/img/Clientes/ClienteWalmart.png`} alt='Dinosaurio mall' />
    <img className='max-w-40 max-h-40 mx-2' src={`${process.env.PUBLIC_URL}/img/Clientes/ClienteVea.png`} alt='Dinosaurio mall' />
    <img className='max-w-40 max-h-40 mx-2' src={`${process.env.PUBLIC_URL}/img/Clientes/ClienteTadicor.png`} alt='Dinosaurio mall' />
    <img className='max-w-40 max-h-40 mx-2' src={`${process.env.PUBLIC_URL}/img/Clientes/ClienteDinoMall.png`} alt='Dinosaurio mall' />
    <img className='max-w-40 max-h-40 mx-2' src={`${process.env.PUBLIC_URL}/img/Clientes/ClienteEasy.png`} alt='Cliente Easy' />
    <img className='max-w-40 max-h-40 mx-2' src={`${process.env.PUBLIC_URL}/img/Clientes/ClienteMultiVoice.png`} alt='Cliente Multivoice' />

  </Slider>;
};


const Clientes = () => {
  return (
    <div>
      <h2 className='justify-center mx-auto mt-28 font-maison text-4xl text-center'>Clientes que confian en Druper</h2>
      <h4 className='mt-6 mb-24 font-satoshi text-base text-gray-400 text-center'>Promovemos un dialogo constante con clientes para comprender lo que necesitan, efectuar <br></br> diagnosticos y elaborar en equipo la mejor solucion, anticipando futuros requerimientos.</h4>
      <ClientesCarousel />
    </div>
  )
}

export default Clientes;