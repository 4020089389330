// Home.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Nosotros from '../components/Nosotros';
import Servicios from '../components/Servicios';
import Hero2 from '../components/Hero2';
import Clientes from '../components/Clientes';
import ContactUs from '../components/ContactUs';

const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Nosotros />
      <Servicios/>
      <Hero2 />
      <Clientes />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default Home;
