import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ContactUs = () => {
  const navigate = useNavigate()
  const whatsappNumber = '5493513064064';
  const handleButtonClick = (redireccion) => {
    navigate(`/${redireccion}`);
  };
  return (
    <>
      <Header />
      <section className="relative bg-black bg-opacity-85 h-[563px] flex">
        <img
          src={`${process.env.PUBLIC_URL}/img/fotoContacto1.jpg`}
          alt="Hero Background"
          className="absolute inset-0 w-full h-full object-cover opacity-60 top-0"
        />
        <div className="relative z-10 flex flex-wrap w-full px-4 lg:px-16">
          <div className="w-full lg:w-1/2 mb-8 lg:mb-0 lg:mt-24">
            <h1 className="text-5xl lg:text-5xl font-bold text-white text-center ml-10 lg:text-left font-maison">
              Contacto
            </h1>
          </div>
          <div className="w-full lg:w-1/2 flex justify-center items-center lg:justify-start">
            <div className='bg-[#228B60] w-[601px] h-[204px] rounded-lg flex flex-col items-center justify-center'>
              <h3 className='text-white font-satoshi font-bold text-xl mb-8'>Solicita nuestros servicios</h3>
              <button onClick={() => window.open(`https://wa.me/${whatsappNumber}`, '_blank')} className='bg-[#E0E0E0] w-3/4 py-2 rounded-md font-bold font-satoshi flex flex-row items-center justify-center gap-8'>Enviar mensaje
                <img
                  src={`${process.env.PUBLIC_URL}/img/Icons/IconoWsp.svg`}
                  alt="Facebook"
                  className="md:max-w-6 md:max-h-6 "
                />
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;
