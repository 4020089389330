import React from 'react';

const Hero = () => {
  return (
    <section className="relative bg-black bg-opacity-85 h-screen flex">
      <img
        src={`${process.env.PUBLIC_URL}/img/Hero1.jpg`}
        alt="Hero Background"
        className="absolute inset-0 w-full h-full object-cover opacity-60"
      />
      <div className="relative z-10 mx-auto content-center lg:ml-16 lg:mb-6">
        <h1 className="text-5xl lg:text-7xl font-bold text-white text-center lg:text-left font-satoshi">
          Gente de soluciones
        </h1>
        <p className="mt-4 text-lg lg:text-3xl text-white lg:text-left font-satoshi">
          Lo impecable es mucho más <br></br> que un concepto; es real
        </p>
      </div>
    </section>
  );
};

export default Hero;