// Empleo.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Empleo = () => {
  return (
    <div>
      <Header />
      <div className='bg-cover bg-center text-black h-64'>
        <div className="flex flex-col justify-center items-center h-full">
          <h1 className="text-3xl mb-8 font-bold font-maison">Empleo en Druper</h1>
          <h2 className='text-xl text-gray-500 font-satoshi'>Ambiente mas agradable por mas tiempo.</h2>
          <h2 className='text-xl text-gray-500 font-satoshi'>Sumate al equipo!</h2>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-center py-8 px-4 lg:px-24 gap-7" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/FotoEmpleoSeccion.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
        }}>
        <div className="bg-[#228B60] text-white p-8 my-24 rounded-lg w-full lg:w-2/5 max-w-[622px]" >
          <h1 className="text-4xl font-bold mb-6 md:mx-4 md:mt-12 font-supreme text-left leading-10">
            ¡Trabaja <br></br> con nosotros!
          </h1>
          <p className="mb-4 md:mx-4 font-satoshi text-base">
            Berlim procura un entorno laboral estable y ameno para cada uno de
            sus operarios. Con un plantel base de 600 personas, programas de
            capacitación y motivación, y un staff profesional de coordinadores y
            supervisores, el área de Recursos Humanos promueve el compañerismo y
            el crecimiento sostenido dentro del rubro.
          </p>
          <button onClick={() => {
            window.location.href = 'mailto:seleccion@druper.com.ar';
          }} className='text-lg font-supreme bg-white text-black rounded-lg py-2  mt-8 mb-8 w-full flex justify-center'>Envianos tu CV
            <svg className='ml-6' xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 28 28" width="28" height="28">
              <g>
                <g id="Layer_1">
                  <g>
                    <path fill="#000000" d="M4.12,8.67l-0.75-0.84-0.66-0.7c-0.16,0.35-0.22,0.73-0.22,1.14v7.49c0,0.81,0.29,1.59,0.75,2.18l0.56-0.59,0.56-0.59,3.5-3.74-3.75-4.1Z" />
                    <path fill="#000000" d="M21.92,7.41l-0.66,0.72-0.75,0.84-3.66,4.13,3.41,3.6,0.56,0.59,0.56,0.59c0.47-0.59,0.75-1.38,0.75-2.23v-7.49c0-0.42-0.07-0.84-0.22-1.19Z" />
                    <path fill="#000000" d="M16.63,17.99l-2.28-2.4-2.12,2.4h-0.25l-2.21-2.43-2.37,2.43-0.75,0.78-0.78,0.81h12.23l-0.75-0.81-0.75-0.78Z" />
                    <path fill="#000000" d="M5.93,6.48l6.13,6.68,6-6.68,0.63-0.72,0.6-0.66c-0.38-0.16-0.75-0.22-1.19-0.22H5.9c-0.44,0-0.85,0.09-1.23,0.22l0.6,0.66,0.66,0.72Z" />
                  </g>
                </g>
              </g>
            </svg>

          </button>
        </div>
        <div className="w-full lg:w-2/5 mt-8 lg:mt-0 lg:ml-8 content-center">


        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Empleo;
