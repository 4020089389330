import React from 'react';
import { useNavigate } from 'react-router-dom';
const Footer = () => {

  const whatsappNumber = '5493513064064';
  const phoneNumber = ' 03515899138'
  const navigate = useNavigate()

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      console.log("Se presiono el boton con el id:", id)
    } else {
      console.log(`Se presiono el elemento con el id ${id} y no paso nada`)
    }
  };

  const handleButtonClick = (redireccion) => {
    navigate(`/${redireccion}`);
  };

  return (
    <footer className="bg-[#F5F5F5] py-8 w-full px-4 md:px-16">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-10 md:flex-row justify-between items-start mb-8">
          <div className="w-full md:w-1/2 mb-4 md:mb-0 col-span-7">

            <div className="flex flex-col md:flex-row items-center mb-4 md:mb-0 mt-12">
              <img
                src={`${process.env.PUBLIC_URL}/logoDruperNegro.png`}
                alt="Logo"
                className="w-[163px] mb-2 md:mb-0"
              />
              <div className="flex space-x-6 md:ml-[35px] mt-2 md:mt-0 items-center">
                <a onClick={() => window.open(`https://wa.me/${whatsappNumber}`, '_blank')} className="text-gray-700 hover:text-black">
                  <svg className="md:min-w-8 md:min-h-8 " xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 400 400">
                    <g>
                      <g id="Layer_1">
                        <path class="cls-1" d="M362.66,182.37c-1.04-13.03-3.65-26.05-7.82-38.56-3.13-9.38-7.29-18.24-11.98-26.58-8.34-14.59-18.24-27.1-30.22-38.04-7.29-6.77-14.59-12.51-22.93-17.72-13.03-8.34-27.09-15.11-41.69-18.76-10.42-3.13-20.32-4.69-30.22-5.73-14.07-1.56-27.62,0-38.04,2.08-8.34,1.04-16.15,3.65-23.97,5.73-10.94,3.13-21.89,7.82-31.79,14.07-7.29,4.69-14.07,9.38-20.84,14.59-9.38,7.82-18.24,16.68-26.58,28.14-8.34,11.46-15.11,24.49-20.32,38.56-4.17,10.42-6.77,21.36-8.34,32.31-2.09,13.03-2.09,26.05-1.04,39.08,1.56,10.94,3.65,20.84,6.25,30.75,3.13,9.9,6.77,18.76,11.47,27.09l-23.97,90.15,20.84-4.17,71.91-18.24c9.9,4.69,19.8,8.34,29.18,10.94,11.99,3.65,25.01,5.21,38.04,5.73,15.63.52,31.26-1.56,46.38-5.73,17.72-4.69,33.87-12.51,48.98-22.93,8.86-6.25,17.2-14.07,25.54-22.41,9.9-10.94,18.24-23.45,25.01-37,6.77-13.55,11.46-28.14,14.07-43.25,1.56-8.86,2.6-18.24,2.6-27.1,0-4.69,0-8.86-.52-13.03ZM262.09,273.04c-6.25,2.08-11.98,3.13-17.72,3.13h-1.04c-9.9,0-19.8-2.08-30.75-6.25-11.46-4.17-22.41-10.42-33.87-18.24-9.9-7.82-18.24-15.11-25.01-23.45-5.21-5.73-11.46-13.03-17.2-21.37-5.21-7.81-8.86-14.07-11.98-20.84-6.77-15.63-6.77-31.79.52-46.9,3.13-6.25,7.3-11.98,12.51-16.15,4.69-4.17,10.94-6.25,17.2-6.25,2.08,0,3.65,0,5.73.52h2.08c9.9,1.56,13.03,5.73,16.68,14.59,1.56,3.13,3.13,6.77,4.17,10.42,2.08,4.17,3.65,8.34,5.21,12.51,4.17,8.34,3.13,17.2-3.64,25.54-1.04,1.56-2.09,3.13-3.65,4.69l-.52.52c6.25,9.38,13.55,17.2,21.89,23.97,5.73,4.17,11.98,7.29,17.72,9.9,3.13-3.65,5.73-6.77,8.86-10.42,1.56-1.56,5.73-7.3,13.55-7.3,3.65,0,6.77,1.56,8.86,2.6l11.98,5.73,14.07,6.77c1.56.52,4.17,2.09,6.25,4.17,3.13,2.6,5.21,6.77,5.21,10.42,1.56,18.24-9.38,34.91-27.1,41.69Z" />
                      </g>
                    </g>
                  </svg>
                </a>
                <a onClick={() => {
                  window.location.href = 'mailto:druper.rrhh@gmail.com';
                }} className="text-gray-700 hover:text-black">
                  <svg className=' md:min-w-10 md:min-h-10' xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 28 28" width="28" height="28">
                    <g>
                      <g id="Layer_1">
                        <g>
                          <path d="M4.12,8.67l-0.75-0.84-0.66-0.7c-0.16,0.35-0.22,0.73-0.22,1.14v7.49c0,0.81,0.29,1.59,0.75,2.18l0.56-0.59,0.56-0.59,3.5-3.74-3.75-4.1Z" />
                          <path d="M21.92,7.41l-0.66,0.72-0.75,0.84-3.66,4.13,3.41,3.6,0.56,0.59,0.56,0.59c0.47-0.59,0.75-1.38,0.75-2.23v-7.49c0-0.42-0.07-0.84-0.22-1.19Z" />
                          <path d="M16.63,17.99l-2.28-2.4-2.12,2.4h-0.25l-2.21-2.43-2.37,2.43-0.75,0.78-0.78,0.81h12.23l-0.75-0.81-0.75-0.78Z" />
                          <path d="M5.93,6.48l6.13,6.68,6-6.68,0.63-0.72,0.6-0.66c-0.38-0.16-0.75-0.22-1.19-0.22H5.9c-0.44,0-0.85,0.09-1.23,0.22l0.6,0.66,0.66,0.72Z" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
                <a
                  href={`tel:${phoneNumber}`}
                  className="text-gray-700 hover:text-black"
                >
                  <svg className="md:min-w-6 md:min-h-6" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 331.59 323">
                    <g id="VekCNZ.tif">
                      <path class="cls-1" d="M331.59,259.91c-3.9,20.46-17.95,33.84-32.06,47.61-14.3,13.96-31.09,17.05-49.77,14.84-27.7-3.28-52.92-13.76-76.59-27.86-62.87-37.44-112.49-88.05-148.5-151.78C13.8,123.49,5.55,103.13,1.7,81.24c-2.07-11.77-2.73-23.56.84-35.23,1.57-5.14,4.16-9.68,7.96-13.51,7.97-8.04,15.35-16.69,24.51-23.47C45.44,1.32,56.74-2.36,69.63,1.62c6.39,1.97,11.99,5.47,16.83,10,14.28,13.34,25.64,28.84,33.07,46.97,7.35,17.92,4.35,34.41-7.92,49.28-2.13,2.58-4.25,5.2-6.7,7.46-4.23,3.88-4.75,8.45-3.23,13.59,1.7,5.76,4.58,10.97,8.11,15.73,20.67,27.81,44.98,51.99,72.95,72.45,5.4,3.95,11.25,7.18,17.9,8.72,4.35,1.01,7.96.18,11.19-3.13,6.18-6.34,12.75-12.25,20.19-17.14,16.41-10.77,33.72-12.38,51.75-4.83,13.82,5.79,25.69,14.48,35.8,25.54,6.25,6.83,10,14.95,12.01,23.92v9.71Z" />
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/4 md:flex flex-col items-center md:items-start col-span-1 md:mt-5 hidden">
            <div className="flex flex-col text-gray-700 mb-4 md:mb-0 text-left gap-2">
              <h3 onClick={() => handleScroll('')} className="text-base font-supreme mb-2">Inicio</h3>
              <h3 onClick={() => handleScroll('servicios')} className="text-base font-supreme mb-2">Servicios</h3>
              <h3 onClick={() => handleButtonClick('contacto')} className="text-base font-supreme mb-2">Empleo</h3>

            </div>
          </div>
          <div className="text-gray-700 col-span-2 text-left md:mt-5">
            <p>(0351) 589 91 38</p>
            <p>info@druper.com.ar</p>
            <p>
              Av. La Voz del Interior Km 8,5. Parque Empresarial Aeropuerto.
              Edif. Cluster Ciiiecca - Torre 1 - Of 2B
            </p>
          </div>
        </div>
        <div className="mt-8 text-center text-sm text-gray-500">
          <p>© 2024 Druper. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
