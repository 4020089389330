import React, { useState } from 'react';

const Nosotros = () => {
  // Estado para controlar si el pop-up está visible
  const [showPopup, setShowPopup] = useState(false);

  // Función para abrir y cerrar el pop-up
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <section id='nosotros' className="bg-[#3D3D40] flex flex-col lg:flex-row items-center lg:h-[667px] lg:items-center justify-normal lg:justify-between">
      <div className="lg:w-1/2 p-8 lg:mb-6 text-white">
        <div className="lg:max-w-[516px] m-auto">
          <h1 className="font-light text-4xl lg:text-5xl mb-6 text-left font-maison">
            Nosotros
          </h1>
          <p className="text-base lg:text-xl text-left font-satoshi">
            Somos un referente en la prestación de sistemas globales de limpieza
            y mantenimiento, especializado en grandes superficies de alto
            tránsito y complejidad.
          </p>
          <p className="mt-4 text-base lg:text-xl text-left font-satoshi">
            Avalada por Berclean S.A., Druper acompaña la localización de sus
            clientes en distintas regiones con una plataforma operativa que
            garantiza estándares premium de calidad y mejoramiento continuo.
            Gracias a nuestros proveedores y red logística ofrecemos los mejores
            plazos y costos para su servicio.
          </p>
          <p className="mt-4 text-base lg:text-xl text-left font-supreme">
            Certificación de normas de calidad ISO 9001-2008.
          </p>
          <div className='flex gap-8 items-center mt-10'>
            <img className='h-24' src={`${process.env.PUBLIC_URL}/img/BureauIso.png`} alt="ISO Certificado"/>
            <button 
              className='bg-white rounded-md text-black px-8 h-11 font-supreme' 
              onClick={togglePopup} // Al hacer clic, abre el pop-up
            >
              Ver certificado
            </button>
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 flex justify-center mb-5 mx-7">
        <img
          src={`${process.env.PUBLIC_URL}/img/Nosotros.jpg`}
          alt="Us"
          className="max-h-[500px] object-cover rounded-3xl"
        />
      </div>

      {/* Pop-up que se muestra si showPopup es true */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="relative bg-white p-8 rounded-lg w-[80%] h-[80%] max-w-4xl">
            <button
              className="absolute top-4 right-4 text-black text-2xl font-bold"
              onClick={togglePopup} // Cierra el pop-up
            >
              &times;
            </button>
            <h2 className="text-xl mb-4 font-supreme">Certificado ISO 9001-2008</h2>
            <iframe
              src={`${process.env.PUBLIC_URL}/CertificadoIso.pdf`} // Ruta del PDF en la carpeta public
              width="100%"
              height="90%"
              className="border z-50"
              title="Certificado ISO"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default Nosotros;
