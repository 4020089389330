import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const HeroCarousel = () => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSLide: false,
    autoplaySpeed: 5000,
  };

  return (
    <Slider {...settings} className="z-50 w-full max-h-[667px]">
      <img alt='' className='object-cover max-h-[667px]' src={`${process.env.PUBLIC_URL}/img/Hero2.png`}/>
      <img alt='' className='object-cover max-h-[667px]' src={`${process.env.PUBLIC_URL}/img/Hero3.png`}/>
      <img alt='' className='object-cover max-h-[667px]' src={`${process.env.PUBLIC_URL}/img/Hero4.png`}/>
      <img alt='' className='object-cover max-h-[667px]' src={`${process.env.PUBLIC_URL}/img/Hero5.png`}/>
      <img alt='' className='object-cover max-h-[667px]' src={`${process.env.PUBLIC_URL}/img/Hero6.png`}/>
      <img alt='' className='object-cover max-h-[667px]' src={`${process.env.PUBLIC_URL}/img/Hero7.png`}/>
      <img alt='' className='object-cover max-h-[667px]' src={`${process.env.PUBLIC_URL}/img/Hero8.png`}/>
    </Slider>
  );
};

const Hero2 = () => {
  return (
      <div>
        <HeroCarousel/>
      </div>
  );
};

export default Hero2;