import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const navigate = useNavigate()
  const toggleMenu = () => {
    if (isMenuOpen) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsMenuOpen(false);
        setIsAnimating(false);
      }, 300); // Duración de la animación
    } else {
      setIsMenuOpen(true);
    }
  };

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      console.log("Se presiono el boton con el id:", id)
    } else {
      console.log(`Se presiono el elemento con el id ${id} y no paso nada`)
    }
    toggleMenu(); // Cierra el menú móvil si está abierto
  };

  const handleButtonClick = (redireccion) => {
    navigate(`/${redireccion}`);
  };

  const whatsappNumber = '5493513064064';
  return (
    <header className="bg-[#EEEEEE] flex justify-between items-center shadow-md w-full px-4 md:px-16 py-4">
      <div className="flex-shrink-0 m-4">
        <img onClick={() => handleButtonClick('')} src={`${process.env.PUBLIC_URL}/logoDruperNegro.png`} alt="Logo" className="w-[200px] h-[66px]" />
      </div>
      <nav className="hidden md:block">
        <ul className="flex text-[20px]">
          <li><button onClick={() => handleButtonClick('')} className="text-gray-700 hover:text-green-400 py-3 px-6">Inicio</button></li>
          <li><button onClick={() => handleScroll('nosotros')} className="text-gray-700 hover:text-green-400 py-3 px-6">Nosotros</button></li>
          <li><button onClick={() => handleScroll('servicios')} className="text-gray-700 hover:text-green-400 py-3 px-6">Servicios</button></li>
          <li><button onClick={() => handleButtonClick('contacto')} className="hover:text-green-400 bg-black text-white py-3 px-6 rounded-md">Contacto</button></li>
        </ul>
      </nav>
      <div className="md:hidden flex items-center">
        <button onClick={toggleMenu} className="text-gray-700 hover:text-green-400 focus:outline-none">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>
      {isMenuOpen && (
        <div className={`md:hidden fixed inset-0 flex justify-end z-10 ${isAnimating ? 'animate-slideOut' : 'animate-slideIn'}`}>
          <div className="w-64 bg-white h-full shadow-xl">
            <div className="p-4 flex justify-between items-center">
              <img src={`${process.env.PUBLIC_URL}/logoDruperNegro.png`} alt="Logo" className="w-[150px]" />
              <button onClick={toggleMenu} className="text-gray-700 hover:text-green-400 focus:outline-none">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
            <ul className="flex flex-col text-base space-y-4">
              <li><button onClick={() => handleScroll('home')} className="text-gray-700 hover:text-green-400 py-3 px-6">Inicio</button></li>
              <li><button onClick={() => handleScroll('about')} className="text-gray-700 hover:text-green-400 py-3 px-6">Nosotros</button></li>
              <li><button onClick={() => handleScroll('servicios')} className="text-gray-700 hover:text-green-400 py-3 px-6">Servicios</button></li>
              <li onClick={() => window.open(`https://wa.me/${whatsappNumber}`, '_blank')}><button onClick={() => window.open(`https://wa.me/${whatsappNumber}`, '_blank')}  className="hover:text-green-400 bg-black text-white py-3 px-6 rounded-md">Contacto</button></li>
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
